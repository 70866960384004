<template>
    <div :id="idName" @click="generate">
      <b-button>
        <slot>Download {{ name }}</slot>
      </b-button>
    </div>
</template>

<script >
  import _ from 'lodash';
  import { saveAs } from 'file-saver';
  import { unparse } from 'papaparse';
  import moment from 'moment';

  // eslint-disable-next-line valid-typeof
  export const isType = (value, type) => typeof value === type;

  export default {
    name: 'JsonCSV',
    props: {
      /**
       * filename to export, default: data.csv
       */
      name: {
        type: String,
        default: 'data.csv'
      },
      /**
       * Delimiter for the CSV file
       */
      delimiter: {
        type: String,
        default: ',',
        required: false
      },
      /**
       * Should the module add SEP={delimiter}
       *
       * Useful for opening file with Excel
       */
      separatorExcel: {
        type: Boolean,
        default: false
      },
      /**
       * What will be the encoding of the file
       */
      encoding: {
        type: String,
        default: 'utf-8'
      },
      /**
       * Advanced options for Papaparse that is used to export to CSV
       */
      advancedOptions: {
        type: Object,
        default: () => {
        }
      },
      /**
       * Labels for columns
       *
       * Object or function
       */
      labels: {
        type: [Object, Function],
        required: false
      },
      /**
       * Used only for testing purposes
       */
      testing: {
        required: false,
        default: false
      }
    },
    computed: {
      // unique identifier
      idName () {
        const now = new Date().getTime();
        return 'export_' + now;
      }
    },
    methods: {
      generate () {
        this.$emit('export-started');
      },
      statementFieldsGenerator (data) {
        const result = data.map((item) => ({
          Extrato: _.get(item, 'statementId'),
          Observação: _.get(item, 'observation'),
          Tipo: _.get(item, 'transactionType') === 'D' ? 'Débito' : 'Crédito',
          Data: this.formatDate(_.get(item, 'created')),
          Duração: new Date(_.get(item, 'mediaLength') * 1000).toISOString().substr(11, 8),
          Usuário: _.get(item, 'login')
        }));
        return result;
      },
      formatDate (value) {
        return moment.unix(value).format('DD/MM/YYYY');
      },
      async export (data) {
        const dataExport = this.statementFieldsGenerator(data);
        if (!dataExport) {
          console.error('No data to export');
          return;
        }

        let csv = unparse(
          dataExport,
          Object.assign(
            {
              delimiter: this.delimiter,
              encoding: this.encoding
            },
            this.advancedOptions
          )
        );
        if (this.separatorExcel) {
          csv = 'SEP=' + this.delimiter + '\r\n' + csv;
        }
        // Add BOM when UTF-8
        if (this.encoding === 'utf-8') {
          csv = '\ufeff' + csv;
        }
        this.$emit('export-finished');
        if (!this.testing) {
          const blob = new Blob([csv], {
            type: 'text/csv;charset=' + this.encoding
          });
          saveAs(blob, this.name);
        }
      }
    }
  };
</script>

<style scoped>
div {
  display: inline;
}
</style>
