<template>
  <div>
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
    <div>
      <b-card no-body v-if="isAdmin">
        <b-tabs pills card>
          <b-tab title="Geral" active>
            <b-card-group deck>
              <b-card no-body class="shadow-none" body-class="card-body-class" v-model="activeUsers">
                <b-card-header header-tag="header" header-class="card-header-class"
                  class="d-flex justify-content-center align-items-center">
                  <h3>Usuários ativos</h3>
                </b-card-header>
                <b-card-body body-class="card-body-class" v-text="activeUsers" />
              </b-card>
              <b-card no-body class="shadow-none" body-class="card-body-class" v-model="inactiveUsers">
                <b-card-header header-tag="header" header-class="card-header-class"
                  class="d-flex justify-content-center align-items-center">
                  <h3>Usuários inativos</h3>
                </b-card-header>
                <b-card-body body-class="card-body-class" v-text="inactiveUsers" />
              </b-card>
            </b-card-group>
          </b-tab>
          <b-tab title="Bônus" @click="onBonusTabClick()">
            <b-col lg="6" class="my-1">
              <b-row>
                <b-form-radio-group v-model="userRadioBonusSelected" buttons>
                  <b-form-radio value="user" v-b-tooltip.hover title="Usuário Responsável">Usuário</b-form-radio>
                  <b-form-radio value="cod" v-b-tooltip.hover title="Código">Código</b-form-radio>
                  <b-form-radio value="name" v-b-tooltip.hover title="Nome">Nome</b-form-radio>
                </b-form-radio-group>
                <b-input-group size="sm" style="{padding-top: 10px; margin-top: 10px;}">
                  <b-input-group-prepend is-text>
                    <b-icon icon="search"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input v-model="filterUserBonusText" v-on:keyup.enter="onUserBonusSearchClick()" type="search"
                    placeholder="Buscar..."></b-form-input>
                </b-input-group>
              </b-row>
            </b-col>
            <b-table striped :fields="fields" :current-page="currentPage" :items="users" :per-page="0" stacked="md">
              <template v-slot:cell(bonus)="row">
                <b-row>
                  <b-button class="item-table" @click="onBonusClick(row.item, row.index, $event.target)">+</b-button>
                </b-row>
              </template>
            </b-table>
            <b-col sm="7" md="6" class="my-1">
              <b-pagination @input="updateBonusPage()" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                align="fill" size="sm" class="my-0"></b-pagination>
            </b-col>
            <b-modal id="modal-prevent-closing" ref="modal" ok-title="Enviar" cancel-title="Cancelar"
              cancel-variant="transparent" :title="modalTitle" @hidden="resetModal" @ok="handleModalOk" size="md">
              <b-form @submit.stop.prevent="handleSubmit">
                <b-row cols="3" cols-sm="3" cols-md="4" cols-lg="3">
                  <b-col class="creditsBalance" style="margin-left=20px"></b-col> <b-col
                    class="mb-2 creditsBalance">Aplicativo </b-col><b-col class="creditsBalance">API</b-col>
                  <b-col class="creditsBalance">Crédito Atual </b-col> <b-col class="creditsBalanceRight">{{new
                    Date(currentCreditAvailable * 1000).toISOString().substring(11, 19)}}</b-col>
                  <b-col class="creditsBalanceRight">{{new Date(currentCreditApi * 1000).toISOString().substring(11,
                    19)}}</b-col>
                  <b-col class="creditsBalance">Reservado </b-col> <b-col class="creditsBalanceRight">{{new
                    Date(currentCreditReserved * 1000).toISOString().substring(11, 19)}}</b-col>
                  <b-col class="creditsBalanceRight">{{new Date(currentCreditReservedApi *
                    1000).toISOString().substring(11, 19)}}</b-col>
                </b-row>
                <br>
                <b-row>
                  <b-col>
                    <b-form-group label="Bônus" label-for="bonus-input" :state="bonusState"
                      invalid-feedback="Não pode ser em branco">
                      <b-form-input id="bonus-input" v-model="bonus" placeholder="Minutos" class="border border-dark"
                        autofocus></b-form-input>
                      <b-form-checkbox style="margin-top: 10px;" switch v-model="api">API</b-form-checkbox>
                      <b-form-checkbox style="margin-top: 10px;" switch
                        v-model="recurrence">Recorrência</b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </b-modal>
          </b-tab>
          <b-tab title="Extrato PJ" @click="onExtractTabClick()">
            <b-col lg="6" class="my-1">
              <b-row>
                <b-form-radio-group v-model="userRadioExtractSelected" buttons>
                  <b-form-radio value="cnpj" v-b-tooltip.hover title="CNPJ">CNPJ</b-form-radio>
                  <b-form-radio value="cod" v-b-tooltip.hover title="Código">Código</b-form-radio>
                  <b-form-radio value="name" v-b-tooltip.hover title="Nome">Nome</b-form-radio>
                </b-form-radio-group>
                <b-input-group size="sm" style="{padding-top: 10px; margin-top: 10px;}">
                  <b-input-group-prepend is-text>
                    <b-icon icon="search"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input v-model="filterUserExtractText" v-on:keyup.enter="onUserExtractSearchClick()"
                    type="search" placeholder="Buscar..."></b-form-input>
                </b-input-group>
              </b-row>
            </b-col>
            <b-table striped :fields="corporateFields" :current-page="currentCorporatePage" :items="corporates"
              :per-page="0" stacked="md">
              <template v-slot:cell(info)="row">
                <b-row>
                  <b-button class="item-table" @click="onInfoClick(row.item, row.index, $event.target)">+</b-button>
                </b-row>
              </template>
            </b-table>
            <b-col sm="7" md="6" class="my-1">
              <b-pagination @input="updateCorporatePage()" v-model="currentCorporatePage" :total-rows="totalCorporateRows"
                :per-page="perCorporatePage" align="fill" size="sm" class="my-0"></b-pagination>
            </b-col>
            <b-modal id="modal-prevent-closing" ref="corporateModal" ok-title="Ok" cancel-variant="transparent"
              :title="corporateModalTitle" @hidden="resetCorporateModal" @ok="handleCorporateModalOk" size="xl">
              <b-form v-on:submit.prevent>
                <b-row>
                  <b-col>
                    <b-form-group label="Data inicial" label-for="date-input">
                      <b-form-datepicker id="example-datepicker" v-model="selectedBeginDate" v-on:input="onDateChange()"
                        v-bind="labels[locale] || {}" reset-button :locale="locale"></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Data final" label-for="date-input">
                      <b-form-datepicker id="example-datepicker2" v-model="selectedFinalDate"
                        v-on:input="onDateChange()" v-bind="labels[locale] || {}" reset-button
                        :locale="locale"></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <h5 style="font-size:10">Total <b-badge class="badge">{{statementTotal}}</b-badge></h5>
                    <download-csv @export-started="generateDataToExport()" ref="csvExporter"
                      :name="statementCsvTitle" />
                  </b-col>
                  <b-col>
                    <div>
                      <b-form-radio-group v-model="selected" buttons>
                        <b-form-radio value="user" v-b-tooltip.hover title="Usuário Responsável">Usuário</b-form-radio>
                        <b-form-radio value="cod" v-b-tooltip.hover title="Código">Código</b-form-radio>
                        <b-form-radio value="description" v-b-tooltip.hover
                          title="Descrição da auditoria">Descrição</b-form-radio>
                        <b-form-radio value="type" v-b-tooltip.hover title="Tipo da transação">Tipo</b-form-radio>
                        <b-form-radio value="duration" v-b-tooltip.hover
                          title="Duração da transação">Duração</b-form-radio>
                      </b-form-radio-group>
                      <div>
                        <b-input-group size="sm" style="{padding-top: 10px; margin-top: 10px;}">
                          <b-input-group-prepend is-text>
                            <b-icon icon="search"></b-icon>
                          </b-input-group-prepend>
                          <b-form-input v-model="filterText" v-on:keyup.enter="onStatementSearchClick()" type="search"
                            placeholder="Buscar..."></b-form-input>
                        </b-input-group>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-table responsive striped :fields="statementFields" :items="statements" @sort-changed="sortChanged"
                    :per-page="0" :current-page="currentStatementPage"
                    style="{ max-height: 300px !important; height: 300px !important; overflow-y: scroll}">
                    <template v-slot:cell(api)="row">
                      <img :src="getApiIconStyle(row.item)">
                    </template>
                  </b-table>
                  <b-row>
                    <b-col class="my-3">
                      <b-pagination @input="updatePage()" v-model="currentStatementPage"
                        :total-rows="totalStatementRows" :per-page="perStatementPage" align="fill" size="sm"
                        class="my-0"></b-pagination>
                    </b-col>
                    <b-col class="my-3">
                      <b-form-select id="per-page-select" v-model="perStatementPage" :options="pageOptions"
                        @input="updatePage()"></b-form-select>
                    </b-col>
                  </b-row>
                </b-row>
              </b-form>
            </b-modal>
          </b-tab>
          <b-tab title="Extrato PF" @click="onBonusTabClick()">
            <b-col lg="6" class="my-1">
              <b-row>
                <b-form-radio-group v-model="userRadioBonusSelected" buttons>
                  <b-form-radio value="user" v-b-tooltip.hover title="Usuário Responsável">Usuário</b-form-radio>
                  <b-form-radio value="cod" v-b-tooltip.hover title="Código">Código</b-form-radio>
                  <b-form-radio value="name" v-b-tooltip.hover title="Nome">Nome</b-form-radio>
                </b-form-radio-group>
                <b-input-group size="sm" style="{padding-top: 10px; margin-top: 10px;}">
                  <b-input-group-prepend is-text>
                    <b-icon icon="search"></b-icon>
                  </b-input-group-prepend>
                  <b-form-input v-model="filterUserBonusText" v-on:keyup.enter="onUserBonusSearchClick()" type="search"
                    placeholder="Buscar..."></b-form-input>
                </b-input-group>
              </b-row>
            </b-col>
            <b-table striped :fields="userFields" :current-page="currentPage" :items="users" :per-page="0" stacked="md">
              <template v-slot:cell(info)="row">
                <b-row>
                  <b-button class="item-table" @click="onInfoClick(row.item, row.index, $event.target)">+</b-button>
                </b-row>
              </template>
            </b-table>
            <b-col sm="7" md="6" class="my-1">
              <b-pagination @input="updateBonusPage()" v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                align="fill" size="sm" class="my-0"></b-pagination>
            </b-col>
            <b-modal id="modal-prevent-closing" ref="corporateModal" ok-title="Ok" cancel-variant="transparent"
              :title="corporateModalTitle" @hidden="resetCorporateModal" @ok="handleCorporateModalOk" size="xl">
              <b-form v-on:submit.prevent>
                <b-row>
                  <b-col>
                    <b-form-group label="Data inicial" label-for="date-input">
                      <b-form-datepicker id="example-datepicker" v-model="selectedBeginDate" v-on:input="onDateChange()"
                        v-bind="labels[locale] || {}" reset-button :locale="locale"></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Data final" label-for="date-input">
                      <b-form-datepicker id="example-datepicker2" v-model="selectedFinalDate"
                        v-on:input="onDateChange()" v-bind="labels[locale] || {}" reset-button
                        :locale="locale"></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <h5 style="font-size:10">Total <b-badge class="badge">{{statementTotal}}</b-badge></h5>
                    <download-csv @export-started="generateDataToExport()" ref="csvExporter"
                      :name="statementCsvTitle" />
                  </b-col>
                  <b-col>
                    <div>
                      <b-form-radio-group v-model="selected" buttons>
                        <b-form-radio value="user" v-b-tooltip.hover title="Usuário Responsável">Usuário</b-form-radio>
                        <b-form-radio value="cod" v-b-tooltip.hover title="Código">Código</b-form-radio>
                        <b-form-radio value="description" v-b-tooltip.hover
                          title="Descrição da auditoria">Descrição</b-form-radio>
                        <b-form-radio value="type" v-b-tooltip.hover title="Tipo da transação">Tipo</b-form-radio>
                        <b-form-radio value="duration" v-b-tooltip.hover
                          title="Duração da transação">Duração</b-form-radio>
                      </b-form-radio-group>
                      <div>
                        <b-input-group size="sm" style="{padding-top: 10px; margin-top: 10px;}">
                          <b-input-group-prepend is-text>
                            <b-icon icon="search"></b-icon>
                          </b-input-group-prepend>
                          <b-form-input v-model="filterText" v-on:keyup.enter="onStatementSearchClick()" type="search"
                            placeholder="Buscar..."></b-form-input>
                        </b-input-group>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-table responsive striped :fields="statementFields" :items="statements" @sort-changed="sortChanged"
                    :per-page="0" :current-page="currentStatementPage"
                    style="{ max-height: 300px !important; height: 300px !important; overflow-y: scroll}">
                    <template v-slot:cell(api)="row">
                      <img :src="getApiIconStyle(row.item)">
                    </template>
                  </b-table>
                  <b-row>
                    <b-col class="my-3">
                      <b-pagination @input="updatePage()" v-model="currentStatementPage"
                        :total-rows="totalStatementRows" :per-page="perStatementPage" align="fill" size="sm"
                        class="my-0"></b-pagination>
                    </b-col>
                    <b-col class="my-3">
                      <b-form-select id="per-page-select" v-model="perStatementPage" :options="pageOptions"
                        @input="updatePage()"></b-form-select>
                    </b-col>
                  </b-row>
                </b-row>
              </b-form>
            </b-modal>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
  import { userService } from '../services/user_service';
  import { storeService } from '../services/store.service';
  import { formatingSecondToDate } from '../helpers/server';
  import JsonCSV from './Export/JsonCSV.vue';
  import doneIcon from '@/assets/icons/done.svg';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import moment from 'moment';

  export default {
    name: 'Admin',
    components: {
      Loading,
      'download-csv': JsonCSV
    },
    data () {
      return {
        doneIcon,
        locale: 'pt-BR',
        labels: {
          'pt-BR': {
            labelPrevDecade: 'Década anterior',
            labelPrevYear: 'Ano passado',
            labelPrevMonth: 'Mês passado',
            labelCurrentMonth: 'Mês atual',
            labelNextMonth: 'Próximo mês',
            labelNextYear: 'Próximo ano',
            labelNextDecade: 'Próxima década',
            labelToday: 'Hoje',
            labelSelected: 'Data selecionada',
            labelNoDateSelected: 'Selecione uma data',
            labelCalendar: 'Calendário',
            labelNav: 'Navegação do calendário',
            labelHelp: 'Navegue pelo calendário com as teclas de seta'
          }
        },
        activeUsers: 0,
        inactiveUsers: 0,
        isLoading: false,
        fullPage: true,
        userInfo: {},
        users: [],
        corporates: [],
        statements: [],
        filterUsers: null,
        filterCorporate: null,
        totalRows: 1,
        totalCorporateRows: 1,
        selectedLang: 'pt',
        totalStatementRows: 0,
        perPage: 15,
        perStatementPage: 10,
        perCorporatePage: 10,
        currentPage: 1,
        currentCorporatePage: 1,
        currentStatementPage: 1,
        modalTitle: 'Adicionar bônus',
        corporateModalTitle: 'Extrato',
        bonus: '',
        bonusState: null,
        currentUser: {},
        isAdmin: false,
        currentCreditAvailable: 0,
        currentCreditReserved: 0,
        currentCreditReservedApi: 0,
        currentCreditApi: 0,
        filterOn: 'name',
        tabType: '',
        fields: [
          { key: 'accountId', label: 'Cód', sortable: true },
          { key: 'name', label: 'Nome', sortable: true },
          { key: 'login', label: 'Login', sortable: true, thStyle: { width: '150px' } },
          { key: 'bonus', label: 'Bonus', sortable: false, thStyle: { width: '150px' } }
        ],
        corporateFields: [
          { key: 'corporateId', label: 'Cód', sortable: true },
          { key: 'name', label: 'Nome', sortable: true },
          { key: 'document', label: 'CNPJ', sortable: true, thStyle: { width: '220px' } },
          {
            key: 'created',
            label: 'Data',
            sortable: true,
            formatter: (value, key, item) => {
              return moment.unix(value).format('DD/MM/YYYY');
            },
            thStyle: { width: '180px' }
          },
          {
            key: 'credits.credits',
            label: 'Créditos disponiveis',
            sortable: false,
            formatter: (value, key, item) => {
              return formatingSecondToDate(value);
            },
            thStyle: { width: '150px' }
          },
          { key: 'info', label: 'Info', sortable: false, thStyle: { width: '150px' } }
        ],
        userFields: [
          { key: 'accountId', label: 'Cód', sortable: true },
          { key: 'name', label: 'Nome', sortable: true },
          { key: 'login', label: 'Login', sortable: true, thStyle: { width: '130' } },
          {
            key: 'userCredits.credits',
            label: 'Créditos Disponíveis',
            sortable: false,
            formatter: (value, key, item) => {
              return formatingSecondToDate(value);
            },
            thStyle: { width: '130' }
          },
          {
            key: 'created',
            label: 'Data',
            sortable: true,
            formatter: (value, key, item) => {
              return moment.unix(value).format('DD/MM/YYYY');
            },
            thStyle: { width: '180px' }
          },
          { key: 'info', label: 'Info', sortable: false, thStyle: { width: '150px' } }
        ],
        statementFields: [
          { key: 'login', label: 'Usuário' },
          { key: 'statementId', label: 'Cód', sortable: true },
          { key: 'observation', label: 'Descrição', sortable: true, thStyle: { width: '400px' } },
          {
            key: 'transactionType',
            label: 'Tipo',
            sortable: true,
            formatter: (value, key, item) => {
              return value === 'D' ? 'Débito' : 'Crédito';
            },
            thStyle: { width: '110px' }
          },
          {
            key: 'created',
            label: 'Data',
            sortable: true,
            formatter: (value, key, item) => {
              return moment.unix(value).format('DD/MM/YYYY');
            },
            thStyle: { width: '140px' }
          },
          {
            key: 'api',
            label: 'API',
            sortable: false,
            thStyle: { width: '100px' }
          },
          {
            key: 'mediaLength',
            label: 'Duração',
            sortable: false,
            formatter: (value, key, item) => {
              return new Date(value * 1000).toISOString().substring(11, 19);
            },
            thStyle: { width: '110px' }
          }
        ],
        statementTotal: 0,
        statementCsvTitle: 'CSV',
        selectedFinalDate: null,
        selectedBeginDate: null,
        pageOptions: [5, 10, 15, { value: 100, text: 'Mostrar 100' }],
        selected: 'user',
        userRadioBonusSelected: 'user',
        userRadioExtractSelected: 'user',
        filterText: '',
        filterUserBonusText: '',
        filterUserExtractText: '',
        corporateId: 0,
        accountId: 0,
        accountLogin: '',
        statementModalLoaded: false,
        adminInfoLoaded: false,
        api: false,
        recurrence: false
      };
    },
    async mounted () {
      await this.loadUserAdminInfo({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage) });
      this.adminInfoLoaded = true;
    },
    async created () {

    },
    methods: {
      getApiIconStyle (item) {
        if (item.api) {
          return doneIcon;
        }
      },
      onStatementSearchClick () {
        if (this.statementModalLoaded) {
          const date = this.filterCreatedDate();
          if (this.selected === 'user' && this.filterText.length > 2) {
            this.loadStatements({ perPage: parseInt(this.perStatementPage), currentPage: parseInt(this.currentStatementPage), user: this.filterText.toLowerCase(), date: date });
          } else if (this.selected === 'cod') {
            this.loadStatements({ perPage: parseInt(this.perStatementPage), currentPage: parseInt(this.currentStatementPage), statementId: this.filterText, date: date });
          } else if (this.selected === 'description' && this.filterText.length > 1) {
            this.loadStatements({ perPage: parseInt(this.perStatementPage), currentPage: parseInt(this.currentStatementPage), observation: this.filterText, date: date });
          } else if (this.selected === 'type') {
            this.loadStatements({ perPage: parseInt(this.perStatementPage), currentPage: parseInt(this.currentStatementPage), transactionType: this.filterText.toUpperCase(), date: date });
          } else if (this.selected === 'duration') {
            this.loadStatements({ perPage: parseInt(this.perStatementPage), currentPage: parseInt(this.currentStatementPage), mediaLength: this.filterText.toLowerCase(), date: date });
          } else {
            this.loadStatements({ perPage: parseInt(this.perStatementPage), currentPage: parseInt(this.currentStatementPage), date: date });
          }
        }
      },
      filterCreatedDate () {
        if (this.selectedBeginDate && this.selectedFinalDate) {
          const beginDate = moment(this.selectedBeginDate).unix();
          const finalDate = moment(this.selectedFinalDate).unix();
          return `${beginDate}|${finalDate}`;
        } else {
          return null;
        }
      },
      onDateChange () {
        if (this.selectedBeginDate && this.selectedFinalDate) {
          this.onStatementSearchClick();
        }
      },
      sortChanged (e) {
        if (this.statementModalLoaded) {
          this.loadStatements({
            perPage: parseInt(this.perStatementPage),
            currentPage: this.currentStatementPage,
            sortBy: e.sortBy,
            sortDesc: e.sortDesc
          });
        }
      },
      async updateCorporatePage () {
        await this.loadCorporateInfo({ perPage: parseInt(this.perCorporatePage), currentPage: parseInt(this.currentCorporatePage) });
      },
      async updatePage () {
        if (this.statementModalLoaded) {
          await this.loadStatements({ perPage: parseInt(this.perStatementPage), currentPage: parseInt(this.currentStatementPage) });
        }
      },
      async updateBonusPage () {
        await this.loadUserAdminInfo({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage) });
      },

      async loadStatements (params) {
        const result = this.tabType === 'corp'
          ? await storeService.extractByCorporate(this.corporateId, params)
          : await userService.statementUserCreditInfo(this.accountId, params);
        this.statements = [];
        if (result && result.length > 0) {
          this.statements = result[0].elements;
          this.pageInfo = result[0].pageInfo;
          this.totalStatementRows = this.pageInfo && this.pageInfo.length > 0 && this.pageInfo[0].count;
        }
        let total = 0;
        for await (const statement of this.statements) {
          total += statement.mediaLength;
          if (this.tabType === 'user') {
            statement.login = this.accountLogin;
          }
        }
        this.statementTotal = new Date(total * 1000).toISOString().substring(11, 19);
      },
      async onBonusClick (item, index, target) {
        this.currentUser = item;
        const credit = await userService.userCredit(item.login);
        if (this.credit === 'Timeout') {
          this.isLoading = false;
          this.$toast.open({ message: 'Timeout de conexão!', type: 'error' });
          return;
        }
        this.currentCreditAvailable = credit.credits;
        this.currentCreditApi = credit.api;
        this.currentCreditReserved = credit.reservado;
        this.currentCreditReservedApi = credit.reservedApi;
        this.$refs.modal.show();
      },
      async onInfoClick (item, index, target) {
        this.pageInfo = null;
        this.totalStatementRows = null;
        this.statementTotal = null;
        console.log(item);
        if (item.corporateId) {
          this.corporateId = item.corporateId;
        } else {
          this.corporateId = null;
          this.accountId = item.accountId;
          this.accountLogin = item.login;
        }
        await this.loadStatements({ perPage: parseInt(this.perStatementPage), currentPage: parseInt(this.currentStatementPage) });
        this.statementModalLoaded = true;
        this.$refs.corporateModal.show();
      },
      async onBonusTabClick () {
        this.tabType = 'user';
        await this.loadUserAdminInfo({ perPage: parseInt(this.perPage), currentPage: 1 });
      },
      async onUserBonusSearchClick () {
        if (this.adminInfoLoaded) {
          if (this.userRadioBonusSelected === 'user' && this.filterUserBonusText.length > 2) {
            await this.loadUserAdminInfo({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage), user: this.filterUserBonusText.trim().toLowerCase() });
          } else if (this.userRadioBonusSelected === 'cod') {
            await this.loadUserAdminInfo({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage), accountId: this.filterUserBonusText.trim() });
          } else if (this.userRadioBonusSelected === 'name' && this.filterUserBonusText.length > 1) {
            await this.loadUserAdminInfo({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage), name: this.filterUserBonusText.trim() });
          } else {
            await this.loadUserAdminInfo({ perPage: parseInt(this.perPage), currentPage: parseInt(this.currentPage) });
          }
        }
      },
      async onUserExtractSearchClick () {
        if (this.adminInfoLoaded) {
          if (this.userRadioExtractSelected === 'cnpj' && this.filterUserExtractText.length > 2) {
            await this.loadCorporateInfo({ perPage: parseInt(this.perCorporatePage), currentPage: parseInt(this.currentCorporatePage), cnpj: this.filterUserExtractText.trim() });
          } else if (this.userRadioExtractSelected === 'cod') {
            await this.loadCorporateInfo({ perPage: parseInt(this.perCorporatePage), currentPage: parseInt(this.currentCorporatePage), corporateId: this.filterUserExtractText.trim() });
          } else if (this.userRadioExtractSelected === 'name' && this.filterUserExtractText.length > 1) {
            await this.loadCorporateInfo({ perPage: parseInt(this.perCorporatePage), currentPage: parseInt(this.currentCorporatePage), name: this.filterUserExtractText.trim() });
          } else {
            await this.loadCorporateInfo({ perPage: parseInt(this.perCorporatePage), currentPage: parseInt(this.currentCorporatePage) });
          }
        }
      },
      async onExtractTabClick () {
        this.tabType = 'corp';
        await this.loadCorporateInfo({ perPage: parseInt(this.perCorporatePage), currentPage: parseInt(this.currentCorporatePage) });
      },
      async loadCorporateInfo (params) {
        this.isLoading = true;
        const result = await userService.corporateInfo(params);
        if (result === 'Timeout') {
          this.isLoading = false;
          this.$toast.open({ message: 'Timeout de conexão!', type: 'error' });
          return;
        }
        this.isLoading = false;
        if (result && result.length > 0) {
          this.corporates = [];
          this.corporates = result[0].elements;
          this.pageInfo = result[0].pageInfo;
          this.totalCorporateRows = this.pageInfo && this.pageInfo.length > 0 && this.pageInfo[0].count;
          console.log(this.corporates);
          console.log(this.pageInfo);
          console.log(this.totalCorporateRows);
          console.log(this.perCorporatePage);
          console.log(this.currentCorporatePage);
        }
      },
      async loadUserAdminInfo (params) {
        this.isLoading = true;
        const result = await userService.userAdminInfo(params);
        if (result && result.length > 0) {
          this.isAdmin = true;
          this.activeUsers = result[1] ? result[1].active : this.activeUsers;
          this.inactiveUsers = result[2] ? result[2].nonActive : this.inactiveUsers;
          this.users = result[0].elements;
          this.pageInfo = result[0].pageInfo;
          this.totalRows = this.pageInfo && this.pageInfo.length > 0 && this.pageInfo[0].count;
        }
        this.isLoading = false;
      },
      resetModal () {
        this.bonus = '';
        this.bonusState = null;
        this.currentUser = {};
        this.currentCredit = 0;
      },
      resetCorporateModal () {
        this.selectedBeginDate = null;
        this.selectedFinalDate = null;
        this.statements = [];
        this.statementModalLoaded = false;
      },
      handleCorporateModalOk (bvModalEvent) {

      },
      handleModalOk (bvModalEvt) {
        bvModalEvt.preventDefault();
        this.handleSubmit();
      },
      async handleSubmit (e) {
        this.bonusState = this.bonus !== '';
        if (!this.bonusState) {
          return;
        }
        this.isLoading = true;
        await userService.bonusRegister({ accountId: this.currentUser.accountId, creditType: 'BN', amountOfTime: this.bonus * 60, api: this.api, recurrence: this.recurrence });
        this.isLoading = false;
        this.$toast.open('Bônus inserido (aii!!) com sucesso!');

        this.$nextTick(() => {
          this.$refs.modal.hide();
        });
      },
      async generateDataToExport () {
        const date = this.filterCreatedDate();
        const params = {
          perPage: 9999,
          currentPage: 1,
          date: date
        };
        if (this.selected === 'user' && this.filterText.length > 2) {
          Object.assign(params, { user: this.filterText.toLowerCase() });
        } else if (this.selected === 'cod') {
          Object.assign(params, { statementId: this.filterText });
        } else if (this.selected === 'description' && this.filterText.length > 1) {
          Object.assign(params, { observation: this.filterText });
        } else if (this.selected === 'type') {
          Object.assign(params, { transactionType: this.filterText.toUpperCase() });
        } else if (this.selected === 'duration') {
          Object.assign(params, { mediaLength: this.filterText.toLowerCase() });
        }
        const result = this.tabType === 'corp'
          ? await storeService.extractByCorporate(this.corporateId, params)
          : await userService.statementUserCreditInfo(this.accountId, params);
        if (this.tabType === 'user') {
          for await (const statement of result[0].elements) {
            statement.login = this.accountLogin;
          }
        }
        if (result && result.length > 0) {
          await this.$refs.csvExporter.export(result[0].elements);
        } else {
          return [];
        }
      }
    }
  };
</script>
<style>
.tab-content h3{
  font-family: Poppins;
  color: #207BDD;
}

.table {
  color: #6278A3;
}
.modal-body {
  margin-left: 20px;
  color: #6278A3;
}
.modal-body h5 {
  color: #6278A3;
}
.modal-title {
  color: #6278A3;
}
 .modal-body .btn-transparent {
    background-color: #e5e5e5;
    color: #007bff;
    font-weight: 500;
  }

  .modal-footer .btn-transparent {
    background-color: #e5e5e5;
    color: #007bff;
    font-weight: 500;
  }
  .modal-footer .btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    padding-left: 20px;
    padding-right: 20px;
  }
.badge {
  margin-left: 10px;
  padding: 6px;
}
.creditsBalance {
  text-align: left;
  color: #6278A3;
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
}
.creditsBalanceRight {
  color: #6278A3;
  font-weight: 400;
  font-size: 14px;
}

</style>
